import {
  camelize,
  truncate,
  formatNumber,
  formatCurrency,
} from "@/helpers/filters.js";

export default {
  name: "TableCampaigns",

  data() {
    return {
      count: 0,
      campaignsBusiness: [],
      isLoading: true,
      totalImpressions: 0,
      totalClicks: 0,
      totalViews: 0,
      totalCostCPM: 0,
      totalCostCPC: 0,
      totalCostCPV: 0,
      variables: {},
      page: 1,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 75],
      },
      headers: [
        { text: "ID", value: "campaign_id", align: "start", sortable: true },
        {
          text: "Campanha",
          value: "campaign_name",
          align: "start",
          sortable: true,
        },
        {
          text: "Cliente",
          value: "advertisers.account_name",
          align: "start",
          sortable: true,
        },
        {
          text: "Período",
          value: "campaign_period",
          align: "start",
          sortable: false,
        },
        {
          text: "Status",
          value: "campaign_status",
          align: "start",
          sortable: true,
        },
        {
          text: "Impressões",
          value: "impressions",
          align: "end",
          sortable: false,
        },
        { text: "Cliques", value: "clicks", align: "end", sortable: false },
        {
          text: "Visualizações",
          value: "views",
          align: "end",
          sortable: false,
        },
        { text: "Custo", value: "coust", align: "end", sortable: false },
      ],
    };
  },

  filters: {
    camelize,
    truncate,
    formatNumber,
    formatCurrency,
  },

  computed: {
    campaignsBusinessState() {
      return this.$store.state.campaignsBusiness;
    },
  },

  watch: {
    "$store.state.countCampaign": {
      async handler() {
        this.count = await this.$store.state.countCampaign;
      },
    },

    campaignsBusinessState: {
      async handler() {
        this.isLoading = true;
        try {
          this.campaignsBusiness = await this.campaignsBusinessState[0];
          if (this.campaignsBusiness.campaigns_business) {
            this.isLoading = false;
            const purchaseData = this.campaignsBusiness.purchase_total_data;
            const costData = this.campaignsBusiness.cost;
            this.totalImpressions = purchaseData.cpm.impressions;
            this.totalClicks = purchaseData.cpc.clicks;
            this.totalViews = purchaseData.cpv.views;
            this.totalCostCPM = costData.cpm;
            this.totalCostCPC = costData.cpc;
            this.totalCostCPV = costData.cpv;
          }
        } catch (error) {}
      },
      deep: true,
    },
  },

  methods: {
    async updateOptions() {
      this.variables = await this.$store.state.variablesQuery;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (sortBy.length === 1 && sortBy[0] !== this.variables.orderby) {
        this.variables.orderby = sortBy[0];
        this.variables.direction = sortDesc[0] ? "asc" : "desc";
        return this.$store.dispatch("getCampaign", this.variables);
      }

      if (
        sortDesc.length === 1 &&
        sortDesc[0] !== (this.variables.direction == "asc")
      ) {
        this.variables.direction = sortDesc[0] ? "asc" : "desc";
        return this.$store.dispatch("getCampaign", this.variables);
      }

      if (
        itemsPerPage !== this.variables.limit ||
        itemsPerPage * page - itemsPerPage !== this.variables.offset
      ) {
        this.variables.offset = itemsPerPage * page - itemsPerPage;
        this.variables.limit = itemsPerPage;
        const payload = {
          offset: this.variables.offset,
          limit: this.variables.limit,
          startDatetime: this.campaignsBusinessState.startDatetime,
          endDatetime: this.campaignsBusinessState.endDatetime,
        };
        window.scrollTo({ top: 0, behavior: "smooth" });
        return this.$store.dispatch("getCampaign", this.variables);
      }
    },
  },

  created() {
    const { startDatetime, endDatetime } = this.campaignsBusinessState;
    this.variables.startDatetime = startDatetime;
    this.variables.endDatetime = endDatetime;
  },
};
