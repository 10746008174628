import gql from "graphql-tag";

export const DATA_HEADER_TEAMS = gql`
  query HeaderTeams($filter: String) {
    teams(filter: $filter) {
      team_id
      team_name
    }
  }
`;
