import Dashboard from "@/views/Dashboard/Dashboard.vue"

export default {
    path: '/dashboard',
    name: 'DashboardRoutes',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
        {
            path : 'campaigns',
            name: 'Campaigns',
            meta: { accessRoute: true },
            component: () => import('@/views/Dashboard/Dashboard.vue'),
        },
    ]
}