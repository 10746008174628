import apollo from "@/plugins/apollo";
import { DATA_TEAM } from "@/graphql/Dashboard/DataTeams"

const teamData = async variables => {
    const response = await apollo.query({
        query: DATA_TEAM,
        variables
    })
    return response.data.team
}

export {
    teamData,
}