import { render, staticRenderFns } from "./RecoverPassword.vue?vue&type=template&id=7995c63c&scoped=true"
import script from "./script.js?vue&type=script&lang=js&external"
export * from "./script.js?vue&type=script&lang=js&external"
import style0 from "@/scss/login.scss?vue&type=style&index=0&id=7995c63c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7995c63c",
  null
  
)

export default component.exports