import Vue from "vue";
import Vuex from "vuex";
import { allCampaignsData, countCampaigns } from "../services";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    countCampaign: 0,
    user: {},
    teamData: {},
    search_date: null,
    variablesQuery: {},
    campaignsBusiness: {},
    inputSearch: {
      text: null,
      site_id: null,
    },
    selectSearch: {
      sites: [],
    },
  },
  mutations: {
    LOGGED_USER(state, payload) {
      state.user = payload;
    },
    LOGOUT_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_INPUT_SEARCH(state, payload) {
      if (payload == null) {
        state.inputSearch.site_id = null;
        state.inputSearch.text = null;
      } else if (typeof payload === "object" && payload !== null) {
        state.inputSearch.site_id = payload.site_id;
      } else {
        state.inputSearch.text = payload;
      }
    },
    SEARCH_DATE(state, payload) {
      state.search_date = payload;
    },
    SET_VISUAL_IDENTITY(state, payload) {
      state.teamData = payload;
    },
    GET_CAMPAIGN(state, payload) {
      state.variablesQuery = payload.variables;
      state.campaignsBusiness = payload;
    },
    COUNT_CAMPAIGN(state, payload) {
      state.countCampaign = payload.count;
    },
  },
  actions: {
    async getCountCampaign({ commit }, payload) {
      let variables = {
        teamId: payload.teamId,
        startDatetime: payload.startDatetime,
        endDatetime: payload.endDatetime,
      };
      try {
        const response = await countCampaigns(variables);
        commit("COUNT_CAMPAIGN", response);
      } catch (error) {}
    },
    async getCampaign({ commit }, payload) {
      commit("GET_CAMPAIGN", {});
      if (!payload.orderby) {
        payload.orderby = "campaign_id";
      }

      let variables = {
        startDatetime: payload.startDatetime,
        endDatetime: payload.endDatetime,
        purchaseTotalDataStartDatetime2: payload.startDatetime,
        purchaseTotalDataEndDatetime2: payload.endDatetime,
        campaignsBusinessStartDatetime2: payload.startDatetime,
        campaignsBusinessEndDatetime2: payload.endDatetime,
        costStartDatetime2: payload.startDatetime,
        costEndDatetime2: payload.endDatetime,
        purchaseTotalDataStartDatetime3: payload.startDatetime,
        purchaseTotalDataEndDatetime3: payload.endDatetime,
        filter: null,
        orderby: payload.orderby,
        direction: payload.direction,
        offset: payload.offset,
        limit: payload.limit,
        teamsFilter2: payload.teamsFilter2,
      };
      try {
        const response = await allCampaignsData(variables);
        const campaignsBusiness = response;
        campaignsBusiness.variables = variables;
        commit("GET_CAMPAIGN", campaignsBusiness);
        return;
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export default store;
