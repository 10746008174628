export default {
    name: "Footer",
  
    data: () => ({
      textFooter: {
        span: "SPACE ADSERVER",
        email: "comercial@adxspace.com.br",
        adress:
          "SHCS 502 Bloco C Loja 37 Parte 2752, Asa Sul, Brasília - DF - 70330-530  |  Copyright © 2022 Space Adtech. Todos os direitos reservados",
      },
    }),
  };