import Vue from 'vue'
import { teamData } from "@/views/services/index"
import Router from 'vue-router'
import AuthenticationRoutes from  './auth.js'
import DashboardRoutes from  './dashboard.js'
import AuthService from '@/components/Auth/services/auth-service'

let url = window.location.hostname
    if(window.location.hostname == 'localhost' || window.location.hostname == 'business.adserver.dev'){
        url = "analytics.adx.space"
    } else {
        url = window.location.hostname
    }

const router = new Router({
    routes: [
        {
            path: "*",
            redirect: "/",
            name: "home",
            meta: { requiresAuth: false }
        },
        AuthenticationRoutes,
        DashboardRoutes
    ],

    scrollBehavior(to, from, savePosition){
        return {
            x: 0,
            y: 0
        }
    },
})

router.beforeEach( async (to, from, next) => {
        try {
            const variables = { filter: `{ 'team_analytics_url' : '${url}' }` }
            const response = await teamData(variables)
            router.app.$store.commit('SET_VISUAL_IDENTITY', response)
            document.title = response.team_name + " - "+ "Business"
        } catch (error) {
        }
    if( to.matched.some(route => route.meta.requiresAuth )){
        if(to.query.s){
            localStorage.setItem('sessionId', to.query.s) 
        }
        const loginRoute = {
            path: '/',
            query: { redirect: to.fullPath }
        }
        if(localStorage.sessionId){
            try {
                const user = await AuthService.loggedUser({ fetchPolicy: 'network-only' })
                router.app.$store.commit('LOGGED_USER', user)
                window.localStorage.setItem('redirect', user.redirect)
                if (to.matched.some(route => route.meta.accessRoute)) {
                    if(user.redirect !== null){
                        return next(from.path)
                    }else{
                        return next()
                    }
                }
                return next()
            } catch (error) {
                return next(loginRoute)
            }
        }
        return next(loginRoute)
    }
    return next()
})

export default router;

Vue.use(Router);