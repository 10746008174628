<template>
  <v-app>
    <Toolbar />
    <router-view />
    <Footer />
  </v-app>
</template>

<script>
import Toolbar from "@/components/Toolbar/Toolbar.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "App",

  components: {
    Toolbar,
    Footer,
  },

};
</script>

<style lang="scss" src="@/scss/general.scss"></style>