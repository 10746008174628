import apollo from "@/plugins/apollo";
import { COUNT_CAMPAINGS } from "@/graphql/Dashboard/CountCampaigns";
import { GET_ALL_CAMPAINGS } from "@/graphql/Dashboard/TableCampaign";
import { DATA_HEADER_TEAMS } from "@/graphql/Dashboard/HeaderTeams";

const getTeams = async (variables) => {
  try {
    const response = await apollo.query({
      query: DATA_HEADER_TEAMS,
      variables,
    });
    return response.data.teams;
  } catch (error) {}
};

const allCampaignsData = async (variables) => {
  console.log(variables);
  try {
    const response = await apollo.query({
      query: GET_ALL_CAMPAINGS,
      variables,
    });
    return response.data.teams;
  } catch (error) {}
};

const countCampaigns = async (variables) => {
  const response = await apollo.query({
    query: COUNT_CAMPAINGS,
    variables,
  });
  return response.data.count_campaigns_business;
};

export { allCampaignsData, countCampaigns, getTeams };
