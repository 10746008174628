import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify.js';
import store from './store/index.js';
import router from './router/index.js';

Vue.config.productionTip = false
var eventBus = new Vue()

new Vue({
  vuetify,
  store,
  router,
  eventBus,
  render: h => h(App)
}).$mount('#app');