import gql from "graphql-tag";

export const GET_ALL_CAMPAINGS = gql`
  query Teams(
    $startDatetime: Int!
    $endDatetime: Int!
    $purchaseTotalDataStartDatetime2: Int
    $purchaseTotalDataEndDatetime2: Int
    $direction: String
    $offset: Int
    $limit: Int
    $campaignsBusinessStartDatetime2: Int!
    $campaignsBusinessEndDatetime2: Int!
    $costStartDatetime2: Int!
    $costEndDatetime2: Int!
    $purchaseTotalDataStartDatetime3: Int!
    $purchaseTotalDataEndDatetime3: Int!
    $teamsFilter2: String
    $filter: String
    $orderby: String
  ) {
    teams(filter: $teamsFilter2) {
      team_id
      team_name
      campaigns_business(
        direction: $direction
        offset: $offset
        limit: $limit
        start_datetime: $campaignsBusinessStartDatetime2
        end_datetime: $campaignsBusinessEndDatetime2
        filter: $filter
        orderby: $orderby
      ) {
        campaign_id
        campaign_name
        campaign_start_datetime
        campaign_end_datetime
        cost(start_datetime: $startDatetime, end_datetime: $endDatetime) {
          cpc
          cpm
          cpv
        }
        account {
          account_id
          account_name
        }
        advertiser {
          account_id
          account_name
        }
        campaign_status {
          status_descr
        }
        purchase_total_data(
          start_datetime: $purchaseTotalDataStartDatetime2
          end_datetime: $purchaseTotalDataEndDatetime2
        ) {
          cpc {
            clicks
          }
          cpm {
            impressions
            mid
          }
          cpv {
            views
          }
        }
      }
      cost(
        start_datetime: $costStartDatetime2
        end_datetime: $costEndDatetime2
      ) {
        cpc
        cpm
        cpv
      }
      purchase_total_data(
        start_datetime: $purchaseTotalDataStartDatetime3
        end_datetime: $purchaseTotalDataEndDatetime3
      ) {
        cpc {
          clicks
        }
        cpm {
          impressions
        }
        cpv {
          views
        }
      }
    }
  }
`;
