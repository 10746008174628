import { EventBus } from "@/components/event-bus.js"
import { format_date, formatUnixTimeStamp, reverseTimestamp } from "@/helpers/filters.js";
import axios from "axios";
import moment from "moment";

export default {
  name: "dialogSendReport",

  data: () => ({
    shakeAnimated: false,
    email: "",
    userHeader: {},
    messageError: "",
    dialogError: false,
    loading: false,
    menu: false,
    datesInput: [],
    dialog: false,
    loadingNotify: false,
    minDate: '',
    maxDate: '',
    dialogNotify: false,
    rulesEmail: [
      (value) => !!value || "Obrigatório",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido";
      },
    ],
  }),

  filters: {
    format_date,
  },

  computed:{
    dataTeams(){
      return this.$store.state.teamData
    },

    dateRangeText() {
        let arrayDateBR = [];
        
        this.datesInput.forEach((date) => {
            let formatDate = moment(date).format('DD/MM/YYYY');
            arrayDateBR.push(formatDate);
        });

        arrayDateBR.sort((a, b) => a - b);

        return arrayDateBR.join(" à ");
    },
  },

  methods: {
        sendFormReport() {
            if (
                !this.email ||
                !this.datesInput ||
                this.datesInput.length <= 0
            ) {
                this.shakeAnimated = true;
                setTimeout(() => {
                this.shakeAnimated = false;
                }, 1000);
            } else {
                this.loading = true
                const payload = {
                report: "team_business",
                date_start: moment(this.datesInput[0]).format("YYYYMMDD"),
                date_end: moment(this.datesInput[1]).format("YYYYMMDD"),
                team_id: this.tabId,
                emails: this.email,
                };

                axios({
                method: "post",

                url: "https://reports.adserver.dev/report",
                headers: {
                    "x-access-session": window.localStorage.sessionId,
                },
                data: payload,
                }).then((response) => {
                this.datesInput = []
                this.dialog = true
                this.$emit('close');
                setTimeout(() => {
                    this.dialog = false;
                    this.loading = false
                }, 3000);
                })
                .catch((error) => {
                this.dialogError = true;
                this.messageError = "Verifique o email cadastrado";
                setTimeout(() => {
                    this.dialogError = false;
                    this.loading = false
                }, 3000);
                })
            }
        },
    },

  props: {
    showModal: Boolean,
    tabId: Number
  },
  created() {
    this.email = this.$store.state.user.user_email;
    // this.createDatasReports();
    EventBus.$on("activeComponent", (value) => {
      this.updateReports(value);
    });
  },
};
