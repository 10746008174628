import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

import pt from 'vuetify/lib/locale/pt'

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        graySpaceBackground: "#44505E",
        grayMediumSpace: "#BCBCBC",
        lightGray: "EDEDED",
        blueSpace: "#2196F3",
        graySpace: "#44505E",
        white: "#FFFFFF",                           
        anchor: "#8c9eff",
        accent: "#2196F3"
      },
      dark: {
        graySpaceBackground: "#1A1A1A",
        grayMediumSpace: "#BCBCBC",
        blueSpace: "#2196F3",
        graySpace: "#0000",
        white: "#FFFFFF",                           
        anchor: "#8c9eff",
        accent: "#2196F3"
      },
    },
  },
})

export default vuetify