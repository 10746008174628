import AuthService from "../Auth/services/auth-service";

export default {
  name: 'Toolbar',

  data: () => ({
    pathLogo: '',
    themeModeTitle: "Escuro",
  }),
  
  methods:{
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if(this.$vuetify.theme.dark === true){
        this.themeModeTitle = "Claro"
      }else{
        this.themeModeTitle = "Escuro"
      }
    },

    async logout() {
      try {
        this.isLoading = true
        await AuthService.logout()
        this.$store.commit('LOGOUT_USER', {})
        localStorage.clear()
        if(this.$route.fullPath !== '/') this.$router.push('/')
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    dataTeams(){
      return this.$store.state.teamData
    }
  }
}