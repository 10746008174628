<template>
  <v-container class="pa-0 container-content">
    <Header />
    <TableCampaigns />
  </v-container>
</template>

<script>
import Header from "@/components/Dashboard/Header/Header.vue";
import TableCampaigns from "@/components/Dashboard/TableCampaigns/TableCampaigns.vue";

export default {
  name: "CampaignsView",

  components: {
    Header,
    TableCampaigns,
  },
};
</script>